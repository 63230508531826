const textArea = document.querySelectorAll('.textarea-with-counter');

textArea.forEach((textarea) => {
  const parentElement = textarea.closest('.mod-input');
  textarea.addEventListener('input', (input) => {
    const characterCountElement = parentElement.querySelector('.mod-input__curr-character');
    characterCountElement.textContent = `${input.target.value.length}`;
  });
});

// Add focus class while start typing
const inputs = document.querySelectorAll('.mod-input__field');
inputs.forEach((input) => {
  const parent = input.closest('.mod-input');
  input.addEventListener('focus', () => {
    parent.classList.add('is-focused');
  });

  input.addEventListener('blur', () => {
    if (!input.value) {
      parent.classList.remove('is-focused');
    }
  });
});

const onlyLettersInputs = document.querySelectorAll('[data-letters-only]');

onlyLettersInputs.forEach((input) => {
  input.addEventListener('input', (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^a-zA-Z\s]/g, '');
  });
});

const numbersFields1 = document.querySelectorAll('[data-fv-numeric]');
const numbersFields2 = document.querySelectorAll('input[type="number"]');
const numbersFields = [...numbersFields1, ...numbersFields2];

numbersFields.forEach((input) => {
  input.addEventListener('input', (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, '');
  });
});
