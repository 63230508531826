// *******************************************************************************
// Form handling
// *******************************************************************************

const handleForm = (form, id) => {
  const formParent = form.closest('[data-parent]');
  const emailError = getEmailError(form);
  const emailFields = formParent.querySelectorAll('input[type="email"]');

  const fieldValidators = {};
  emailFields.forEach((field) => {
    const fieldName = field.name;

    if (!fieldName) {
      return;
    }

    fieldValidators[fieldName] = {
      validators: {
        callback: {
          message: emailError,
          callback(input) {
            return validateEmail(input.value);
          },
        },
      },
    };
  });

  const fv = FormValidation.formValidation(form, {
    locale: 'sl_SI',
    localization: FormValidation.locales.sl_SI,
    fields: fieldValidators,
    plugins: {
      message: new FormValidation.plugins.Message({
        container: (field, element) => element.closest('.mod-input,.checkbox,.radio-button,.dropdown,.counter'),
      }),
      declarative: new FormValidation.plugins.Declarative({
        html5Input: true,
      }),
      trigger: new FormValidation.plugins.Trigger({
        event: 'submit',
      }),
      submitButton: new FormValidation.plugins.SubmitButton(),
    },
  })
    .on('core.element.validated', (e) => {
      if (e.element.hasAttribute('required')) {
        const value = fv.getElementValue(e.field, e.element);
        const container = FormValidation.utils.closest(
          e.element,
          '.mod-input,.checkbox,.radio-button,.dropdown,.counter',
        );
        FormValidation.utils.classSet(container, {
          'is-validation-failed': value === '' || !e.valid,
        });

        calcFormHeight();
      }
    })
    .on('core.form.valid', () => {
      const baseUrl = /^(localhost|.*\.specto\.work)$/.test(window.location.hostname)
        ? 'https://fitfinance.specto.work'
        : 'https://fitfinance.gbkr.si';

      const url = `${baseUrl}/wp-admin/admin-ajax.php?action=contactFormSubmissionAPI`;
      const formTitle = formParent.querySelector('[data-form-title]').getAttribute('data-form-title');
      const firstName = formParent.querySelector('#ime').value;
      const lastName = formParent.querySelector('#priimek').value;
      const email = formParent.querySelector('#contact_email').value;
      const phoneNumber = formParent.querySelector('#phone').value;
      const quizResult = Cookies.get('vzgoja_otroka_result');
      const newsletterCheck = !!formParent.querySelector('#newsletter_check').checked;

      const data = {
        api_key: 'HlrYApq9e2T0iStgUlWaYEkDGOHgGHuy6XBMDzhiOvHGwztG',
        form_id: id,
        form_title: formTitle,
        url: window.location.href,
        email,
        first_name: firstName,
        last_name: lastName,
        language: 'sl',
        newsletter_checkbox: newsletterCheck,
        telephone: phoneNumber,
        quiz_result: quizResult,
      };

      formParent.classList.add('show-loader');
      fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(() => {
          const formModal = formParent.closest('.form-modal');
          formModal.classList.remove('is-open-modal');
          formParent.classList.remove('show-loader');
          calcModuleHeight(1);
        })
        .catch((error) => console.error('Error:', error));
    });
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.form-component__form').forEach((form) => {
    const id = form.getAttribute('id');
    if (form !== null) {
      handleForm(form, id);
    }
  });
});

// Check if all inputGroups in group have same name
const inputGroupParents = document.querySelectorAll('[data-input-group-parent]');

inputGroupParents.forEach((formRow) => {
  const inputGroups = formRow.querySelectorAll('[data-input-group-field]');

  if (inputGroups.length > 1) {
    // Check if failed
    const observer = new MutationObserver(() => {
      const inputParents = formRow.querySelectorAll('[data-input-group-item]');
      const hasFailedRadio = [...inputParents].some((inputParent) =>
        inputParent.classList.contains('is-validation-failed'),
      );
      formRow.classList.toggle('failed', hasFailedRadio);
    });

    inputGroups.forEach((input) =>
      observer.observe(input.closest('[data-input-group-item]'), { attributes: true, attributeFilter: ['class'] }),
    );

    // Get the first error message and create a new div
    if (formRow.classList.contains('input-group')) {
      const errorMessage = formRow.querySelector('[data-fv-not-empty___message]');
      if (errorMessage) {
        const groupErrorMessage = document.createElement('div');
        groupErrorMessage.classList.add('fv-plugins-message-container', 'group-error');
        groupErrorMessage.innerHTML = errorMessage.getAttribute('data-fv-not-empty___message');

        formRow.appendChild(groupErrorMessage);
      }
    }
  }
});

attachEvent('.accordion-title', 'click', (btn) => {
  const parent = btn.target.closest('.form-component');
  const accordionContent = parent.querySelector('.form-component__hidden-text');
  slideToggle(accordionContent, 0, 'block');
  calcFormHeight();
});
